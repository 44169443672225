import React from 'react';
import { graphql } from 'gatsby';
import { Detail, Seo } from 'components';

export default ({
  data: {
    page: {
      frontmatter: {
        title,
        full_title,
        tags,
        logo,
        announce,
        site,
        og,
        headBg,
        themeInner,
        description,
        seo_h1,
        heading_text,
        announce_title
      },
      body
    }
  }
}) => (
  <>
    <Seo
      title={(seo_h1 || full_title || title) + ' | кейс Nimax'}
      og={og}
      description={(description || seo_h1 || full_title || title) + ' — проект digital-агентства Nimax'}
    />
    <Detail
      title={full_title || title}
      button={site ? { text: 'Посетить сайт', url: /^http/.test(site) ? site : 'https://' + site, icon: 'link' } : null}
      tags={tags ? tags.split(',') : []}
      logo={logo}
      suptitle={announce_title ?? 'Клиент и&nbsp;задача'}
      announce={announce}
      headBg={headBg}
      themeInner={themeInner}
      seo_h1={seo_h1}
    >
      {body}
    </Detail>
  </>
);

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        announce_title
        full_title
        tags
        logo
        announce
        site
        og
        description
        seo_h1
        heading_text
      }
      body
    }
  }
`;
